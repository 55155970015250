import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

export default ({ data }) => {
  const { content } = data;
  const { title, lang } = content.frontmatter;
  return (
    <Layout title={title} lang={lang}>
      <Container maxWidth="md">
        <Box m={2} p={5}>
          <div dangerouslySetInnerHTML={{__html: content.html}} />
        </Box>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query($id: String) {
    content: markdownRemark(id: { eq: $id }) {
      frontmatter {
        lang
        title
      }
      html
    }
  }
`;
